import { createApi } from "@reduxjs/toolkit/query/react";
import { camelizeKeys, decamelizeKeys } from "humps";

import { PaginationQuery } from "../components/tables/types";
import { CreateInvitationRequestType, CreateInvitationType, Invitation } from "../models/Invitation";
import { ListRes, filterEmpyValues, getBaseQuery, getBaseUrl } from "./utils";

const url = `${getBaseUrl()}/invitations`;
const tagType = "invitations";

export const invitationsApi = createApi({
  reducerPath: "invitationsApi",
  tagTypes: [tagType],
  baseQuery: getBaseQuery(url),
  endpoints: (build) => ({
    getInvitations: build.query<
      ListRes<Invitation>,
      { pagination: PaginationQuery, filter?: unknown, searchTerm?: string }
    >({
      query: (body) => {
        const data = {
          ...body.pagination,
          filter: filterEmpyValues(body.filter || {}),
        };

        return {
          url: "list",
          method: "POST",
          body: decamelizeKeys(data),
        };
      },
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<Invitation>) =>
        camelizeKeys(response) as ListRes<Invitation>,
    }),
    createInvitation: build.mutation<void, CreateInvitationType>({
      query: (data) => {
        const sendingData: CreateInvitationRequestType =  {
          source: data.source,
          token: data.token,
          fbPixel: data.fbPixel,
          channelId: data.channel?.channelId || "",
          botId: data.bot?.id || "",
          sub1: data.sub1,
          sub2: data.sub2,
          sub3: data.sub3,
          sub4: data.sub4,
          sub5: data.sub5,
        };
        if (data.user) {
          sendingData.userId = data.user.id;
        }
        if (!data.sub1) delete sendingData.sub1;
        if (!data.sub2) delete sendingData.sub2;
        if (!data.sub3) delete sendingData.sub3;
        if (!data.sub4) delete sendingData.sub4;
        if (!data.sub5) delete sendingData.sub5;

        return ({
          url: "",
          method: "POST",
          body: decamelizeKeys(sendingData),
        });
      },
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
  }),
});

export const {
  useLazyGetInvitationsQuery,
  useCreateInvitationMutation,
} = invitationsApi;
