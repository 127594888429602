import { decamelizeKeys, camelizeKeys } from "humps";
import { createApi } from "@reduxjs/toolkit/query/react";
import sha1 from "sha1";

import { getBaseQuery, ListRes } from "./utils";
import { CreateSmsTemplate, DataType, DeleteSmsTemplate, GetSmsTemplate, SmsTemplate, UpdateSmsTemplate } from "../models/SmsTemplate";

type BodyData = (CreateSmsTemplate | UpdateSmsTemplate | DeleteSmsTemplate | GetSmsTemplate) & { type: DataType };

const createBody = (data: BodyData) => decamelizeKeys({
  timeout: 60,
  ops: [{
    convId: "1156813",
    type: "create",
    obj: "task",
    data,
  }]
});

const createUrl = (body: object) => {
  /* eslint-disable  @typescript-eslint/no-var-requires */
  const secretKey = "t1uP1IJDNLDXc4qMcm7xrIyfun1bYcVwebZYgFuRVEZ5f90qxa";
  const apiLogin = 122860;
  const date = Math.floor(Date.now() / 1000);

  const signature = date + secretKey + JSON.stringify(body) + secretKey;
  const hash = sha1(signature);

  return `/${apiLogin}/${date}/${hash}`;
};

const createRequest = (data: BodyData) => {
  const body = createBody(data);
  return {
    url: createUrl(body),
    method: "POST",
    body,
  };
};

const baseUrl = "https://sync-api.corezoid.com/api/1/json";
const tagType = "smsTemplates";

export const smsTemplatesApi = createApi({
  reducerPath: "smsTemplatesApi",
  tagTypes: [tagType],
  baseQuery: getBaseQuery(baseUrl),
  endpoints: (build) => ({
    getItems: build.query<ListRes<SmsTemplate>, GetSmsTemplate>({
      query: (data) => createRequest({ ...data, type: "get" }),
      providesTags: (result) =>
        result
          ? [
            ...result.list.map(({ id }) => ({ type: tagType, id } as const)),
            { type: tagType, id: "LIST" },
          ]
          : [{ type: tagType, id: "LIST" }],
      transformResponse: (response: ListRes<SmsTemplate>) =>
        camelizeKeys(response) as ListRes<SmsTemplate>,
    }),
    updateItem: build.mutation<void, UpdateSmsTemplate>({
      query: (data) => createRequest({ ...data, type: "modify" }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    createItem: build.mutation<void, CreateSmsTemplate>({
      query: (data) => createRequest({ ...data, type: "create" }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
    deleteItem: build.mutation<void, DeleteSmsTemplate>({
      query: (data) => createRequest({ ...data, type: "delete" }),
      invalidatesTags: [{ type: tagType, id: "LIST" }],
    }),
  }),
});

export const {
  useGetItemsQuery,
  useUpdateItemMutation,
  useDeleteItemMutation,
  useCreateItemMutation,
} = smsTemplatesApi;
